import {
  WebComponentData,
  ScriptType,
} from '@wix/ambassador-app-service-webapp/types';

export interface IWebComponent {
  componentId?: string;
  data?: WebComponentData;
}

export interface IDashboardComponentData {
  checkStatusUrl?: string;
  embedded?: boolean;
  hideWixSideMenu?: boolean;
  published?: boolean;
  settingsPageUrl?: string;
  url?: string;
}

export interface IDashboardComponent {
  data?: IDashboardComponentData;
}

export const getCustomElementStructure = ({
  applicationId,
  data,
  instanceId,
  metaSiteDataAndDefault = {},
  isPage = false,
  formFactor = 'Desktop',
  widgetId = '',
}: {
  applicationId: string;
  data: WebComponentData;
  instanceId: string;
  isPage?: boolean;
  metaSiteDataAndDefault?: any;
  formFactor?: string;
  widgetId?: string;
}) => {
  let mergedConfig = metaSiteDataAndDefault;
  if (JSON.stringify(mergedConfig).length > 1023) {
    mergedConfig = {
      message:
        'Seems that you reached the data limitation. plesae set "Data Endpoint URL" as an option to save the data to external DB',
    };
  }
  const initialAttributes = JSON.stringify({
    wixconfig: JSON.stringify({
      instanceId,
      viewMode: 'Editor',
      deviceType: formFactor,
    }),
    wixsettings: JSON.stringify(mergedConfig),
  });
  return {
    componentType: 'platform.components.AppWidget',
    type: 'Container',
    data: {
      applicationId,
      id: 'custom_element_id',
      controllerType: widgetId,
      type: 'AppController',
      name: '',
    },
    style: 'appWidget1',
    layout: {
      x: isPage ? 0 : data?.size?.width ? 490 - data?.size?.width / 2 : 400,
      y: isPage ? 0 : 64,
      width: isPage ? 980 : data?.size?.width,
      height: isPage ? 980 : data?.size?.height,
    },
    components: [
      {
        componentType: 'wixui.CustomElementComponent',
        data: {
          url: `${
            data?.scriptTag?.indexOf('?') !== -1
              ? `${data.scriptTag}&instanceId=${instanceId}`
              : `${data.scriptTag}?instanceId=${instanceId}`
          }`,
          scriptType:
            data?.scriptType === ScriptType.MODULE ? 'module' : undefined,
          tagName: data.tagName,
          type: 'CustomElement',
          role: 'webComponent',
          initialAttributes,
        },
        layout: {
          x: 0,
          y: 0,
          width: '100%',
          height: '100%',
        },
        mobileStructure: {
          layout: {
            height: data?.defaultMobileHeight,
          },
        },
        style: {},
        config: {},
      },
    ],
  };
};
